<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">ADI and Spec Grading Quiz</h2>

      <p class="mb-n3">a) What is a scientific argument?</p>

      <v-radio-group v-model="inputs.sciArgument" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in sciArgumentOptions"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) What is the benefit of engaging in a scientific argument?</p>

      <v-radio-group
        v-model="inputs.sciArgumentBenefits"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in sciArgumentBenefitsOptions"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        sciArgument: null,
        sciArgumentBenefits: null,
      },
      sciArgumentOptions: [
        {text: 'A personal disagreement between two scientists', value: 'a'},
        {text: 'A false theory', value: 'b'},
        {text: 'A conversation & explanation of scientific ideas', value: 'c'},
        {text: 'A scientific claim', value: 'd'},
      ],
      sciArgumentBenefitsOptions: [
        {text: 'An argument provides criticism to hinder controversial work', value: 'a'},
        {text: 'An argument is a final report of theory to the scientific community', value: 'b'},
        {text: 'An argument reveals flaws in scientific thinking', value: 'c'},
      ],
    };
  },
};
</script>
<style scoped></style>
